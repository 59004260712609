import React from "react"

import Layout from "../../../components/layout_en"
import Seo from "../../../components/seo"
import { Link, graphql } from "gatsby"
import {Container, Row, Col, Card, Button} from "react-bootstrap"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline";
/*import CONFIG from '../../global-vars-trezo.js'*/
import {GatsbyImage} from "gatsby-plugin-image";
import trezoPlan from "../../../images/tree.svg"



const Suivi = ({data}) => (
    <Layout>

        <Seo
            titleTemplate={`Real-time automated treasury management software | Trezorino`}
            title="Real-time automated treasury management software"
            description="Automated cash management to save time, reduce the risk of errors, and eliminate unproductive tasks."
            image={trezoPlan}
            lang="en"
        />

        <div>

            <Container>
                {data.first.edges.map(document => (
                    <Row className="home-box-header justify-content-center" key={document.node.id} >
                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1">

                            <h1 className="title">The more efficient digital cash flow statement</h1>
                            <p className="section">Forget your excel file and benefit from a digital cash flow statement, powered automatically and in real time. </p>
                            <p className="section">Trezorino offers you a complete view of your past, present and future financial situation.</p>
                            <p className="section">Analyzing your cash flow becomes easier and allows you to quickly identify where to focus your attention.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                                </Col>
                            </Row>

                        </Col>

                        <Col className="home-box-image order-1 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {/*  {section1.node.video &&

                            <video className="box-shadow" key={section1.node.video.id} src={section1.node.video.url} width="100%" height="100%" loop muted playsInline autoPlay></video>
                        }*/}

                            {document.node.Image480?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} className="" key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="container-blue-inverse container-line" >
                {data.second.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.Image480?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">Synchronization of your bank accounts</h2>
                            <p className="section-h2">With more than 300 banking connectors your cash flow is automatically updated.</p>
                            <p className="section-h2">Avoid errors and tedious manipulations with manual imports of your banking transactions in Excel.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="container-line" >
                {data.trois.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="my-auto home-box-text order-1 order-sm-1 order-lg-1">

                            <h2 className="title">Automatically categorize your cash flows</h2>
                            <p className="section-h2">Your cash flow statement is organized by revenue and expense categories. Determine which categories are appropriate for your business for a better analysis of your cash inflows and outflows.</p>
                            <p className="section-h2">Our intelligent algorithms automatically categorize transactions so that your cash flow statement is updated automatically.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                                </Col>
                            </Row>

                        </Col>
                        <Col className="home-box-image order-2 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="container-blue container-line" >
                {data.quatre.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">Smart bank reconciliation</h2>
                            <p className="section-h2">Don't waste your valuable time manually reconciling bank accounts.</p>
                            <p className="section-h2">Trezorino speeds up this process and automatically generates matching suggestions to better assist you in this task.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="container-line" >
                {data.cinq.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="my-auto home-box-text order-1 order-sm-1 order-lg-1">

                            <h2 className="title">A cash flow statement for each entity</h2>
                            <p className="section-h2">Create as many cash flow statements as there are entities or agencies.</p>
                            <p className="section-h2">Manage cash flows between your entities and stay in control of your group's financial strategy.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Demander une demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>
                            </Row>

                        </Col>
                        <Col className="home-box-image order-2 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container>

                    <Row className="justify-content-center pb-4 pt-5">
                        <h2 className="center message-title">Do always more with Trezorino.</h2>
                    </Row>




                <Row xs={2} sm={2} md={2} lg={4} xl={4} className="g-4" style={{backgroundColor: "transparent"}}>

                    {data.allStrapiFooterMenus.edges.map(menu => (

                        <Card className="card-box" key={menu.node.id}>
                            {menu.node.MainImage.map( ImageCard=> (
                                <GatsbyImage key={ImageCard.id}  image={ImageCard?.localFile.childImageSharp.gatsbyImageData} alt="text a définir"/>
                            ))}
                            <Card.Body>
                                <Card.Title className="center card-title" >{menu.node.LinkText}</Card.Title>
                            </Card.Body>
                            <Card.Footer className="justify-content-center card-footer">
                                <Link to={"/en"+menu.node.LinkHref+"/"}  className="card-link-footer">Learn more<IoChevronForwardOutline></IoChevronForwardOutline></Link>
                                {/* <Button variant="primary" className="collab-button-footer-card"  href={"/fr"+menu.node.LinkHref}  >Essai gratuit</Button> */}
                            </Card.Footer>
                        </Card>

                    ))}

                </Row>








                <Row className="justify-content-md-center pb-5 pt-5">

                </Row>

            </Container>



        </div>


    </Layout>
)

export default Suivi



export const data = graphql`  
query suivi_en {
  first : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_1"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  second : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_2"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  trois : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_3"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  quatre : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_4"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  cinq : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_5"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  all : allStrapiFeaturesSuivis(
    filter: {id: {ne: "Features-suivis_1"}}
    sort: {order: ASC, fields: Order}
  ) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  allStrapiFooterMenus (
  sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        Title
        LinkText
        LinkHref
        Order
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiTitleMenuFooter {
    edges {
      node {
        id
        Title
      }
    }
  }
}
`
